import Modal from "@/Components/Modal";
import {useEffect, useRef, useState} from "react";
import TextInput from "@/Components/TextInput";
import PrimaryButton from "@/Components/PrimaryButton";
import Spinner from "@/Components/Spinner";
import {RemoteUpload} from "@/Pages/Dashboard/Upload/UploadModal";
import {v4 as uuidv4} from "uuid";
import uploadRemoteVideo from "@/services/remoteVideoUploader";
import toast from "react-hot-toast";
import ProgressBar from "@/Components/ProgressBar";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export type UploadUrlModalProps = {
    show: boolean,
    onClose: () => void,
    onUploadComplete: (upload: RemoteUpload) => void,
    youtube?: boolean
}

export default function RemoteUploadModal({show, onClose, onUploadComplete, youtube = true }: UploadUrlModalProps) {
    const [url, setUrl] = useState<string>('');
    const [progress, setProgress] = useState<number>(0);
    const [processing, setProcessing] = useState<boolean>(false);

    function handleClose() {
        if (!processing || confirm('Are you sure you want to cancel the upload?')) {
            onClose()
        }
    }

    const handleUpload = async () => {
        try {
            setProcessing(true)
            const {fileName, fileSize, id } = await uploadRemoteVideo(url, setProgress);
            const upload: RemoteUpload = {id, url, fileName, fileSize, progress: 1, options: {}}
            onUploadComplete(upload);
        } catch (e: any) {
            toast.error(e.message || 'Failed to upload video. Please try again later.');
        } finally {
            setProcessing(false);
            setProgress(0)
        }
    }

    useEffect(() => {
        if(!show) {
            setUrl('');
            setProgress(0);
            setProcessing(false);
        }
    }, [show])

    return (<Modal maxWidth='md' show={show} onClose={handleClose}>
        <div id="default-modal" className="w-full">
            <div className="flex items-center p-4 md:p-7">
                <div className="w-8 h-8"></div>
                <div className="flex-grow flex justify-center">
                    <h3 className="text-lg font-semibold text-gray-900 ">
                        Upload From URL
                    </h3>
                </div>
                <button type="button"
                        onClick={handleClose}
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center !outline-none"
                >
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>
            <div className="px-8 pb-6">
                <div className="mb-6">
                    <label htmlFor="default-input"
                           className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Video URL</label>
                    <TextInput
                        id="name"
                        className="mt-1 block w-full"
                        value={url}
                        onChange={(e) => {
                            setUrl(e.target.value)
                        }}
                        placeholder="https://"
                        required
                        isFocused
                    />
                    <span className="text-sm block text-gray-500 pt-2"><FontAwesomeIcon icon={faLink} size="sm" className="mr-1"/> The URL must be publicly accessible.</span>
                    { !youtube && (
                        <div
                            className="p-4 mt-3 pb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                            role="alert">
                            <b>Note:</b> Youtube is currently not working, but we are working on a fix.
                        </div>
                    )}
                </div>

                {
                    progress > 0 && (
                        <div className="mb-2">
                            <ProgressBar progress={progress * 100} height="sm"/>
                        </div>
                    )
                }
                <PrimaryButton disabled={processing} onClick={() => url && handleUpload()} className="w-full">
                    <span className="flex justify-center items-center">
                        <span>Upload</span> {processing && <Spinner size={4} className="ml-2 fill-white"/>}
                    </span>
                </PrimaryButton>
            </div>

        </div>
    </Modal>)
}
